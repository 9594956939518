import { api } from '@/api'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { useProgrammatic } from '@oruga-ui/oruga-next'

import Home from '../views/Home.vue'
import { Ref, ref } from 'vue'

const { oruga } = useProgrammatic()

export const breadCrumbStack: Ref<Array<string>> = ref([])

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: () => {
      breadCrumbStack.value = []
    }
  },
  {
    path: '/template/download',
    name: 'Download Template',
    component: () => import('../views/TemplateDownload.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Download Template']
    }
  },
  {
    path: '/template/secure-link',
    name: 'Generate Secure Upload Link',
    component: () => import('../views/SecureLink.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Generate Secure Upload Link']
    }
  },
  {
    path: '/template/secure-link-sent/:email?',
    name: 'Secure Upload Link Generated',
    component: () => import('../views/SecureLinkSent.vue'),
    props: true,
    beforeEnter: (to, from) => {
      // check that redirect is from app
      // avoid fake/scam redirect
      if (from.name === 'Generate Secure Upload Link') {
        breadCrumbStack.value = ['Generate Secure Upload Link', 'Secure Upload Link Generated']

        return true
      } else {
        router.push('/')
      }
    }
  },
  {
    path: '/template/upload/:token?',
    name: 'Upload Template Landing',
    component: () => import('../views/TemplateUploadLanding.vue')
  },
  {
    path: '/template/upload',
    name: 'Upload Template',
    component: () => import('../views/GenericUploadForm.vue'),
    meta: { requireToken: true },
    children: [
      {
        path: 'incidental/:token?',
        name: 'Incidental Occurrences Data Upload',
        component: () => import('../components/Forms/IncidentalForm.vue')
      },
      {
        path: 'systematic/:token?',
        name: 'Systematic Survey Data Upload',
        component: () => import('../components/Forms/SystematicSurveyForm.vue')
      }
    ]
  },
  {
    path: '/template/upload/summary/:submissionID?',
    name: 'Submission Status',
    component: () => import('../views/SubmissionSummary.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Submission Status']
    }
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['404']
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {
  if (to.matched.some((m) => m.meta.requireToken)) {
    // If the route or a parent route requires a token
    const token = to.params.token as string
    try {
      const refresh = await api.auth.refreshToken(token)
      to.params.accessToken = refresh.data.access

      breadCrumbStack.value = to.name ? [to.name as string] : []
      return true
    } catch (e) {
      const errMsg = 'Authentication failed'

      oruga.notification.open({
        component: {
          props: [],
          template: `
          <div class="columns is-mobile">
            <div class="column">
            An error occurred: <b>${errMsg}</b>. Please request another <a @click="$emit('close')" href="${
              router.resolve({
                name: 'Generate Secure Upload Link'
              }).href
            }">Secure Upload Link</a> or contact support.
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column has-text-right">
              <o-button type="button" variant="primary" inverted @click="$emit('close')">OK</o-button>
            </div>
          </div>
          `
        },
        variant: 'danger',
        position: 'bottom',
        indefinite: true,
        closable: true
      })

      router.push('/')
    }
    return true
  }
})

export default router
