import * as Vue from 'vue'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import App from './App.vue'
import router from './router'

import '@/assets/main.scss'

// biome-ignore format:
Vue.createApp(App)
  .use(router)
  .use(Oruga, bulmaConfig)
  .mount('#app')
